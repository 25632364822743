import React from "react";
import { Row, Col, Card } from "antd";
import { LineChartOutlined, ShopOutlined } from "@ant-design/icons";
import Header from "../components/Header";
import { CheckCircleOutlined, CarryOutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { api } from "../services/vendor.service";
import { useEffect } from "react";
import { useState } from "react";

function Home() {
  const navigate = useNavigate();
  const username = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState({});

  const getData = () => {
    api.get_dashboard().then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* <Header title={`Welcome ${username.first_name}.`} /> */}
      <div>
        <Row className="mt-5" gutter={20}>
          <Col span={24} className="mb-5">
            <p className="mb-1"> Welcome Back,</p>
            <h1 className="font-bold text-lg">{username.first_name}.</h1>
          </Col>
          <Col span={12}>
            <div
              className="p-3 text-slate-500 rounded border-2 border-slate-200 flex justify-center align-center flex-col text-center cursor-pointer 
            hover:bg-slate-200 transition "
              onClick={() => {
                navigate("/orders");
              }}
            >
              <ShopOutlined style={{ fontSize: 50 }} className="mt-3" />
              <h5 className="text-slate-700 mt-4 text-lg w-full">
                Pickup Stores
              </h5>
            </div>
          </Col>
          <Col span={12}>
            <div
              className="p-3 text-slate-500 rounded border-2 border-slate-200 flex justify-center align-center flex-col text-center cursor-pointer
            hover:bg-slate-200 transition "
              onClick={() => {
                navigate("/delivery-list");
              }}
            >
              <CarryOutOutlined style={{ fontSize: 50 }} className="mt-3" />
              <h5 className="text-slate-700 mt-4 text-lg w-full">Deliveries</h5>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col span={24} lg={8} className="mb-0 mt-5">
            <div className="bg-slate-100 border border-slate-200 p-2 rounded-md">
              <div className="flex justify-between align-center">
                <h2 className="text-md text-slate-700 mb-0">
                  Total Deliveries
                </h2>
                <h2 className="text-lg font-semibold mb-0 pr-2">
                  {data?.product_count}
                </h2>
              </div>
            </div>
          </Col>
          <Col span={24} lg={8} className="mb-0 mt-5">
            <div className="bg-amber-50 border border-amber-100 p-2 rounded-md">
              <div className="flex justify-between align-center">
                <h2 className="text-md text-slate-700 mb-0">
                  Pending Deliveries
                </h2>
                <h2 className="text-lg font-semibold mb-0 pr-2">
                  {data?.picked_order_count}
                </h2>
              </div>
            </div>
          </Col>
          <Col span={24} lg={8} className="mb-0 mt-5">
            <div className="bg-green-100 border border-green-200 p-2 rounded-md">
              <div className="flex justify-between align-center">
                <h2 className="text-md text-slate-700 mb-0">
                  Completed Deliveries
                </h2>
                <h2 className="text-lg font-semibold mb-0 pr-2">
                  {data?.delivered_order_count}
                </h2>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
