import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// Styles
import "./App.css";
import "./styles/global.scss";
import "antd-css-utilities/utility.min.css";
import "antd/dist/antd.css";

// Route protection
import { ProtectedRoutes } from "./protected/protectedroute";

// Pages
import DashboardLayout from "./layout/DashboardLayout";
import NotFound from "./pages/extras/NotFound";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/register/Register";
// Branches
// Offers
// Wallet
// Digital store
// Products
import AddProduct from "./pages/products/AddProduct";
import EditProduct from "./pages/products/EditProduct";
import ManageProducts from "./pages/products/ManageProducts";
import ViewProduct from "./pages/products/ViewProduct";
// Services
//
import OrdersList from "./pages/orders/OrdersList";
import OrderDetails from "./pages/orders/OrderDetails";
import OrderPickupList from "./pages/orders/OrderPickupList";
import OrderDeliveryList from "./pages/orders/OrderDeliveryList";
//
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import ForgetPassowrd from "./pages/account/ForgetPassword";
import UserDetails from "./pages/account/UserDetails";

const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forget-password" element={<ForgetPassowrd />} />

        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<DashboardLayout />}>
            <Route index element={<Home />} />
            {/* Products */}
            <Route path="products" element={<ManageProducts />} />
            <Route path="products/create" element={<AddProduct />} />
            <Route path="products/view" element={<ViewProduct />} />
            <Route path="products/edit" element={<EditProduct />} />
            {/*  */}
            <Route path="orders" element={<OrdersList />} />
            <Route path="orders/pickup-list" element={<OrderPickupList />} />
            <Route path="orders/view" element={<OrderDetails />} />
            <Route path="delivery-list" element={<OrderDeliveryList />} />
            {/* account */}
            <Route path="edit-profile" element={<UserDetails />} />
          </Route>
        </Route>
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
