// import React, { useEffect, useState } from "react";
// import { Row, Col, Card, Button, Image, Modal, Alert, Skeleton } from "antd";
// import { products } from "../../services/vendor.service";
// import Header from "../../components/Header";
// import moment from "moment";
// import { createAlert } from "../../utilities/alert";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { DeleteOutlined } from "@ant-design/icons";
// import PageBreadcrumb from "../../components/PageBreadcrumb";

// function ViewProduct() {
//   const [searchParams] = useSearchParams();
//   const [product, setProduct] = useState(null);
//   const [productImages, setProductImages] = useState(null);
//   const [deleteId, setDeleteId] = useState(null);
//   const [loading, setLoading] = useState(null);
//   const [modalVisible, setModalVisible] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     setLoading(true);
//     if (searchParams.get("id")) {
//       async function getData() {
//         return await products.getSingle(searchParams.get("id")).then((res) => {
//           console.log(res);
//           setLoading(false);
//           if (res.success) {
//             setProduct(res.data);
//             setProductImages(res.data.image);
//           } else {
//             createAlert("error", res.message);
//           }
//         });
//       }
//       getData();
//     } else {
//       createAlert("error", "Product not found.");
//     }
//   }, [searchParams]);

//   const hideModal = () => {
//     setModalVisible(false);
//   };

//   const handleImageDelete = async () => {
//     setLoading(true);
//     setModalVisible(false);
//     await products.deleteImage(deleteId).then((res) => {
//       setLoading(false);
//       console.log(res);
//       if (res.success) {
//         createAlert("success", "Image successfully deleted.");
//         let remaining = productImages.filter((e) => {
//           return e.id !== deleteId;
//         });
//         setProductImages(remaining);
//       } else {
//         createAlert("error", res.message);
//       }
//     });
//   };

//   return (
//     <>
//       {product && !loading ? (
//         <>
//           <PageBreadcrumb
//             items={[
//               { link: "/", title: "Home" },
//               { link: "/products", title: "Products" },
//               { title: product.title },
//             ]}
//           />
//           <div className="d-flex justify-space-between align-content-center align-center">
//             <Header title={product.title} />
//           </div>
//           <Card title="Product Details" bordered={true}>
//             <Row>
//               <Col span={4}>
//                 <small> Branch </small>
//                 <h3 className="m-0">{product.store}</h3>
//               </Col>

//               <Col span={4}>
//                 <small> Category </small>
//                 <h3 className="m-0"> {product.category} </h3>
//               </Col>
//               <Col span={4}>
//                 <small> Stock </small>
//                 <h3 className="m-0"> {product.balance} </h3>
//               </Col>
//               <Col span={4}>
//                 <small> MRP </small>
//                 <h3 className="m-0"> ₹ {product.mrp_price} </h3>
//               </Col>
//               <Col span={4}>
//                 <small> Selling price </small>
//                 <h3 className="m-0"> ₹ {product.selling_price} </h3>
//               </Col>
//               <Col span={4}>
//                 <small> Verified </small>
//                 <h3 className="m-0"> {product.verified.toString()} </h3>
//               </Col>
//             </Row>
//           </Card>
//         </>
//       ) : (
//         ""
//       )}

//       {!loading && productImages && productImages.length > 0 ? (
//         <Card
//           title="Product Images"
//           loading={loading}
//           className="mt-8"
//           bordered={true}
//         >
//           <div className="d-flex flex-row">
//             {productImages.map((e) => (
//               <div
//                 key={e.id}
//                 style={{
//                   position: "relative",
//                 }}
//               >
//                 <Image
//                   width={150}
//                   rootClassName="mr-5"
//                   style={{ border: "1px solid #eee" }}
//                   src={`https://backend.Quzone22.com/${e.image}`}
//                 ></Image>{" "}
//                 {/* <DeleteOutlined
//                   onClick={() => {
//                     setDeleteId(e.id);
//                     setModalVisible(true);
//                   }}
//                   style={{
//                     position: "absolute",
//                     background: "rgba(238,238,238,.68)",
//                     cursor: "pointer",
//                     padding: 10,
//                     borderRadius: 500,
//                     top: 5,
//                     right: 25,
//                   }}
//                 /> */}
//               </div>
//             ))}
//           </div>
//         </Card>
//       ) : (
//         <Card
//           title="Product Images"
//           loading={loading}
//           className="mt-8"
//           bordered={true}
//         >
//           <Alert message="No images available." type="warning" />
//         </Card>
//       )}
//       <Modal
//         title="Modal"
//         visible={modalVisible}
//         onOk={handleImageDelete}
//         onCancel={hideModal}
//         okButtonProps={{ type: "danger" }}
//         okText="Delete Image"
//         cancelText="Cancel"
//       >
//         <p>Are you sure you want to delete the image ?</p>
//       </Modal>
//     </>
//   );
// }

// export default ViewProduct;

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Image, Modal, Alert, Skeleton } from "antd";
import { products } from "../../services/vendor.service";
import Header from "../../components/Header";
import moment from "moment";
import { createAlert } from "../../utilities/alert";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import PageBreadcrumb from "../../components/PageBreadcrumb";

function ViewProduct() {
  const [searchParams] = useSearchParams();
  const [product, setProduct] = useState(null);
  const [productImages, setProductImages] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (searchParams.get("id")) {
      async function getData() {
        return await products.getSingle(searchParams.get("id")).then((res) => {
          console.log(res);
          setLoading(false);
          if (res.success) {
            setProduct(res.data);
            setProductImages(res.data.image);
          } else {
            createAlert("error", res.message);
          }
        });
      }
      getData();
    } else {
      createAlert("error", "Product not found.");
    }
  }, [searchParams]);

  const hideModal = () => {
    setModalVisible(false);
  };

  const handleImageDelete = async () => {
    setLoading(true);
    setModalVisible(false);
    await products.deleteImage(deleteId).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        createAlert("success", "Image successfully deleted.");
        let remaining = productImages.filter((e) => {
          return e.id !== deleteId;
        });
        setProductImages(remaining);
      } else {
        createAlert("error", res.message);
      }
    });
  };

  return (
    <>
      {product && !loading ? (
        <>
          <PageBreadcrumb
            items={[
              { link: "/", title: "Home" },
              { link: "/products", title: "Products" },
              { title: product.title },
            ]}
          />
          <div className="d-flex justify-space-between align-content-center align-center">
            <Header title={product.title} />
          </div>
          <Card title="Product Details" bordered={true}>
            <Row>
              <Col span={4}>
                <small> Category </small>
                <h3 className="m-0"> {product.category} </h3>
              </Col>
              <Col span={4}>
                <small> Available Stock (Nos.) </small>
                <h3 className="m-0"> {product.balance} </h3>
              </Col>
              <Col span={4}>
                <small> MRP </small>
                <h3 className="m-0"> ₹ {product.mrp_price} </h3>
              </Col>
              <Col span={4}>
                <small> Tax </small>
                <h3 className="m-0"> {product.tax_amount} % </h3>
              </Col>
              <Col span={4}>
                <small> Selling price </small>
                <h3 className="m-0"> ₹ {product.selling_price} </h3>
              </Col>
              <Col span={4}>
                <small> Active </small>
                <h3 className="m-0"> {product.active.toString()} </h3>
              </Col>
              <Col span={24} className="mt-5">
                <small> Short Description </small>
                <h3 className="m-0"> {product.short_description} </h3>
              </Col>
              <Col span={24} className="mt-5">
                <small> Long Description </small>
                <h3 className="m-0"> {product.long_description} </h3>
              </Col>
            </Row>
          </Card>
        </>
      ) : (
        ""
      )}

      {!loading && productImages && productImages.length > 0 ? (
        <Card
          title="Product Images"
          loading={loading}
          className="mt-8"
          bordered={true}
        >
          <div className="d-flex flex-row">
            {productImages.map((e) => (
              <div
                key={e.id}
                style={{
                  position: "relative",
                }}
              >
                <Image
                  width={150}
                  rootClassName="mr-5"
                  style={{ border: "1px solid #eee" }}
                  src={`https://backend.Quzone22.com${e.image}`}
                ></Image>{" "}
                {/* <DeleteOutlined
                  onClick={() => {
                    setDeleteId(e.id);
                    setModalVisible(true);
                  }}
                  style={{
                    position: "absolute",
                    background: "rgba(238,238,238,.68)",
                    cursor: "pointer",
                    padding: 10,
                    borderRadius: 500,
                    top: 5,
                    right: 25,
                  }}
                /> */}
              </div>
            ))}
          </div>
        </Card>
      ) : (
        <Card
          title="Product Images"
          loading={loading}
          className="mt-8"
          bordered={true}
        >
          <Alert message="No images available." type="warning" />
        </Card>
      )}
      <Modal
        title="Modal"
        visible={modalVisible}
        onOk={handleImageDelete}
        onCancel={hideModal}
        okButtonProps={{ type: "danger" }}
        okText="Delete Image"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete the image ?</p>
      </Modal>
    </>
  );
}

export default ViewProduct;
