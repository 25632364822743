import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Table, Space, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { products } from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";

function ManageProducts() {
  const [productsList, setProductsList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function getData() {
      return await products
        .get(localStorage.getItem("store"))
        .then((res) => {
          setLoading(false);
          console.log(res);
          setProductsList(res.data);
        })
        .catch((err) => {
          createAlert("error", err.message);
        });
    }
    getData();
  }, []);

  const getData = async () => {
    await products
      .get(localStorage.getItem("store"))
      .then((res) => {
        setLoading(false);
        console.log(res);
        setProductsList(res.data);
      })
      .catch((err) => {
        createAlert("error", err.message);
      });
  };

  const handleStatusChange = (id) => {
    products
      .changeStatus({
        product_id: id,
      })
      .then(async (res) => {
        console.log(res);
        if (res.success) {
          setLoading(true);
          await getData();
          setLoading(false);
        }
      });
  };

  const handleStockChange = (id) => {
    products
      .changeStockStatus({
        product_id: id,
      })
      .then(async (res) => {
        console.log(res);
        if (res.success) {
          setLoading(true);
          await getData();
          setLoading(false);
        }
      });
  };

  const columns = [
    {
      title: "ID",
      key: 5,
      dataIndex: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["ascend"],
      render: (text, record, key) => {
        return <Link to={`/products/view?id=${record.id}`}> {text} </Link>;
      },
    },

    {
      title: "MRP",
      dataIndex: "mrp",
      key: 1,
    },
    {
      title: "Selling price",
      dataIndex: "price",
      key: 2,
      sorter: (a, b) => new Date(a.price) - new Date(b.price),
      sortDirections: ["descend"],
    },
    {
      title: " In Stock",
      key: "in_stock",
      dataIndex: "in_stock",
      render: (text, record) => (
        <Space size="middle">
          <Row>
            <Space>
              <Switch
                checked={record.in_stock}
                onChange={(e) => {
                  console.log(record.active);
                  handleStockChange(record.id);
                }}
              />
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: "Active",
      key: "id",
      dataIndex: "active",
      render: (text, record) => (
        <Space size="middle">
          <Row>
            <Space>
              <Switch
                checked={record.active}
                onChange={(e) => {
                  console.log(record.active);
                  handleStatusChange(record.id);
                }}
              />
            </Space>
          </Row>
        </Space>
      ),
    },

    {
      title: "Actions",
      key: 4,

      render: (text, record) => (
        <Space size="middle">
          <Row>
            <Space>
              <Button
                title="Edit"
                onClick={(e) => {
                  navigate(`/products/edit?id=${record.id}`);
                  console.log(record.id);
                }}
              >
                Edit
              </Button>
            </Space>
          </Row>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageBreadcrumb
        items={[{ link: "/", title: "Home" }, { title: "Products" }]}
      />
      <div className="d-flex justify-space-between align-content-center align-center">
        <Header title="Manage Products" />
        <Button
          type="primary"
          onClick={() => {
            navigate("/products/create");
          }}
          icon={<PlusCircleOutlined />}
        >
          Create New Product
        </Button>
      </div>

      <Table
        scroll={{ x: "500px" }}
        loading={loading}
        pagination={false}
        dataSource={productsList}
        rowKey={"id"}
        columns={columns}
      />
    </>
  );
}

export default ManageProducts;
