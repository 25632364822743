import {
  ExclamationCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Alert } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { api } from "../../services/vendor.service";

function OrdersList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    setLoading(true);
    async function getData() {
      return await api.get_delivery_stores().then((res) => {
        console.log(res.data);
        setList(res.data.data);
      });
    }
    getData();
  }, []);

  return (
    <>
      <PageBreadcrumb items={[{ title: "Pickup stores" }]} />

      <div className="d-flex justify-space-between align-content-center align-center mb-5 mt-3">
        <h5 className="font-bold text-xl"> Pickup stores </h5>
      </div>

      {list?.map((e) => {
        return (
          <div className="p-4 bg-slate-100 rounded-md mb-5" key={e.id}>
            <div
              className="flex flex-wrap flex-col lg:flex-row 
              lg:flex-nowrap
             lg:align-center 
             lg:justify-between 
             sm:justify-center 
             sm:align-start
             "
            >
              <div>
                <h5 className="font-bold text-lg mb-0"> {e.store_name} </h5>
                <p className="opacity-60 mb-0 capitalize text-xs">
                  {e.address}
                </p>
              </div>

              <div>
                <button
                  className="mt-6 mb-3 py-2 px-5 w-full bg-slate-500 hover:shadow-lg hover:bg-slate-700
                  transition text-white rounded-full flex justify-center align-center"
                  onClick={() => {
                    navigate(`/orders/pickup-list?id=${e.id}`);
                    // console.log();
                  }}
                >
                  Pickup orders
                  <RightCircleOutlined className="ml-3 text-lg" />
                </button>
              </div>
            </div>
          </div>
        );
      })}

      {!list.length ? (
        <Alert
          icon={<ExclamationCircleOutlined />}
          type="warning"
          showIcon
          message="No deliveries available."
        />
      ) : (
        ""
      )}
    </>
  );
}

export default OrdersList;
