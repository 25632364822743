import React from "react";

function Header(props) {
  return (
    <>
      <div
        style={{
          paddingTop: 24,
          paddingBottom: 24,
        }}
      >
        <h2 style={{ margin: 0, fontWeight: 600 }}> {props.title} </h2>
      </div>
    </>
  );
}

export default Header;
