import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Radio,
  Checkbox,
  Button,
  InputNumber,
  DatePicker,
  Steps,
  Upload,
  Select,
} from "antd";
import { RightCircleOutlined, UploadOutlined } from "@ant-design/icons";

import {
  api_submitShop,
  get_shopCategories,
  api_getCategories,
  api_checkMobile,
  api,
} from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";

import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

const { Step } = Steps;
const { Option } = Select;

function Register() {
  const [searchParams] = useSearchParams();
  const [referred, setReferred] = useState(null);
  const navigate = useNavigate();

  // Checking if link has
  useEffect(() => {
    if (searchParams.get("link")) {
      setReferred(searchParams.get("link"));
    } else {
      setRefferalVisible(true);
    }
  }, [referred, searchParams]);

  useEffect(() => {}, []);

  // States

  const [mainCategories, setMainCategories] = useState([]);
  const [refferalVisible, setRefferalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [aadhar, setAadhar] = useState(null);
  const [pan, setPan] = useState(null);
  const [gst, setGst] = useState(null);
  const [generalFormValues, setGeneralFormValues] = useState({});
  //   Form handling
  const handleGeneralFormSuccess = (values) => {
    // alert(1);
    api_checkMobile(values.mobile).then((res) => {
      console.log(res.data);
      setLoading(false);
      if (res.data.success) {
        let paylaod = { ...values, role: "delivery" };
        api.user_register(paylaod).then((res) => {
          console.log(res.data);
          if (res.data.success === true) {
            createAlert("success", "Successfully registerd as delivery.");
            navigate("/");
          } else {
            createAlert("error", res.data.message);
          }
        });
      } else {
        createAlert("error", res.data.message);
      }
    });
  };
  const handleGeneralFormError = (error) => {
    console.log(error);
  };

  return (
    <>
      {/* Vendor register form */}
      <div className="container w-100 pt-4">
        <Row>
          <Col span={12} offset={6}>
            <h1 className="text-2xl my-8 font-bold">Delivery Registration.</h1>
          </Col>
        </Row>
      </div>

      <div className="container w-100">
        <Row>
          <Col span={12} offset={6}>
            <div className="form-wrapper">
              <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={handleGeneralFormSuccess}
                onFinishFailed={handleGeneralFormError}
                autoComplete="off"
              >
                <Form.Item
                  label="Enter First name"
                  className="hide-astricks"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your firsname.",
                    },
                  ]}
                >
                  <Input placeholder="Enter first name" />
                </Form.Item>
                <Form.Item
                  label="Enter Email Address"
                  name="email"
                  type="email"
                  className="hide-astricks"
                  hasFeedback
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please enter your correct email address.",
                    },
                  ]}
                >
                  <Input placeholder="Enter email address" />
                </Form.Item>
                <Form.Item
                  label="Enter Password"
                  className="hide-astricks"
                  name="password"
                  type="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password.",
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter password" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  className="hide-astricks"
                  name="confirmPassword"
                  type="password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm your password" />
                </Form.Item>
                <Form.Item
                  className="hide-astricks"
                  label="Mobile"
                  name="mobile"
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: "Please enter your number!",
                    },
                  ]}
                >
                  <InputNumber
                    type="number"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Enter your mobile number"
                  />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("You Should accept agreement.")
                            ),
                    },
                  ]}
                >
                  <Checkbox>I accept to the terms &amp; Conditions.</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    className="mt-4"
                    loading={loading}
                  >
                    Submit
                    <RightCircleOutlined />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>

      {/* Shop details form */}
    </>
  );
}

export default Register;
