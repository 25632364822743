import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Alert, Col, Input, Modal, Radio, Row, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";
import { Spin } from "antd";
import PageBreadcrumb from "../../components/PageBreadcrumb";

function OrderDeliveryList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  // Modal
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [otp, setOtp] = useState(null);
  const [orderId, setOrderId] = useState(null);
  //
  const [status, setStatus] = useState("");
  const [cancleReason, setCancleReason] = useState("");
  // cancel modal
  const [cancleModal, setCancleModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState(false);

  const toggleModal = (e) => {
    setVisible(e);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      toggleModal(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const cancle_delivery = () => {
    api
      .change_order_status({
        status: "cancelled",
        order_id: orderId,
        notes: cancleReason,
      })
      .then((res) => {
        if (res.data.success) {
          createAlert("success", "Delivery Cancelled succesfully.");
          setStatus("4");
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        createAlert("error", err.message);
      });
  };

  const set_out_for_delivery = (id) => {
    api
      .change_order_status({
        status: "out_for_delivery",
        order_id: id,
        notes: "",
      })
      .then((res) => {
        if (res.data.success) {
          createAlert("success", "Delivery started successfully.");
          setStatus("9");
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        createAlert("error", err.message);
      });
  };

  const complete_delivery = async (id) => {
    await api
      .change_order_status({
        status: "delivered",
        order_id: orderId,
        notes: "",
      })
      .then((res) => {
        if (res.data.success) {
          createAlert("success", "Package successfully delivered.");
          setStatus("2");
          toggleModal(false);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        createAlert("error", err.message);
      });
  };

  const pending_deliveries = () => {
    let a = list.filter((e) => e.status === "picked");
    return a ? true : false;
  };

  const verify_customer_otp = (id) => {
    api
      .verify_customer_otp({
        order_id: orderId,
        otp: otp,
      })
      .then(async (res) => {
        if (res.data.success) {
          // createAlert("success", "OTP verified successfully.");
          setStatus("1");
          await complete_delivery();
        } else {
          createAlert("error", res.data.message);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        createAlert("error", err.message);
      });
  };

  const get_all_deliveries = useCallback(async () => {
    setLoading(true);
    await api
      .get_orders("all", "picked")
      .then((res) => {
        setLoading(false);
        console.log("delivery list", res.data.data);
        setList(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  }, []);

  const get_deliveries = async (status) => {
    setLoading(true);
    await api
      .get_orders("all", status)
      .then((res) => {
        setLoading(false);
        console.log("delivery list", res.data.data);
        setList(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  useEffect(() => {
    get_all_deliveries();
  }, [get_all_deliveries, status]);

  return (
    <>
      <PageBreadcrumb items={[{ title: "Delivery List" }]} />

      <div className="d-flex justify-space-between align-content-center align-center mb-5 mt-3">
        <h5 className="font-bold text-xl"> Your Deliveries </h5>
      </div>

      <div className="filters flex mb-8 overflow-x-scroll pb-5">
        <div
          className={`
          rounded-full flex-shrink-0  py-1 px-4 mr-3
          ${
            activeFilter === "pending"
              ? "bg-amber-500 border border-amber-500 text-white shadow-lg"
              : "bg-amber-50 border border-amber-200"
          }`}
          onClick={() => {
            setActiveFilter("pending");
            get_deliveries("picked");
          }}
        >
          Pending
        </div>
        <div
          className={`
          rounded-full flex-shrink-0  py-1 px-4 mr-3
          ${
            activeFilter === "ongoing"
              ? "bg-blue-500 border border-blue-500 text-white shadow-lg"
              : "bg-blue-50 border border-blue-200"
          }`}
          onClick={() => {
            setActiveFilter("ongoing");
            get_deliveries("out_for_delivery");
          }}
        >
          On Going
        </div>
        <div
          className={`
          rounded-full flex-shrink-0  py-1 px-4 mr-3
          ${
            activeFilter === "completed"
              ? "bg-green-500 border border-green-500 text-white shadow-lg"
              : "bg-green-50 border border-green-200"
          }`}
          onClick={() => {
            setActiveFilter("completed");
            get_deliveries("delivered");
          }}
        >
          Delivered
        </div>
        <div
          className={`
          rounded-full flex-shrink-0  py-1 px-4 mr-3
          ${
            activeFilter === "cancelled"
              ? "bg-red-500 border border-red-500 text-white shadow-lg"
              : "bg-red-50 border border-red-200"
          }`}
          onClick={() => {
            setActiveFilter("cancelled");
            get_deliveries("cancelled");
          }}
        >
          Cancelled
        </div>
      </div>

      {/* list */}

      {loading ? (
        <Spin spinning={loading} />
      ) : (
        <div className="list">
          {list.map((e) => {
            return (
              <div
                key={e.id}
                className="p-5 bg-slate-100 border border-slate-200 rounded-md mb-4"
              >
                <Row>
                  <Col lg={4} span={12} className="mb-3">
                    <p className="mb-0 opacity-70 text-xs"> Parcel ID </p>
                    <h6 className="font-semibold text-md"> {e.id} </h6>
                  </Col>
                  <Col lg={8} span={12} className="mb-2">
                    <p className="mb-0 opacity-70 text-xs"> Customer Name </p>
                    <h6 className="font-semibold text-md">
                      {e.address.first_name}
                    </h6>
                  </Col>
                  <Col lg={4} span={12} className="mb-3">
                    <p className="mb-0 opacity-70 text-xs"> Product </p>
                    <h6 className="font-semibold text-md"> {e.product} </h6>
                  </Col>
                  <Col lg={4} span={12} className="mb-3">
                    <p className="mb-0 opacity-70 text-xs"> Quantity </p>
                    <h6 className="font-semibold text-md"> {e.quantity} </h6>
                  </Col>

                  <Col span={24} className="mb-2">
                    <p className="mb-0 opacity-70 text-xs">
                      {" "}
                      Delivery Address{" "}
                    </p>
                    <h6 className="font-semibold text-md">
                      {e.address.address_line_1} <br />
                      {e.address.address_line_2} <br />
                      {e.address.city} - {e.address.pincode}
                    </h6>
                  </Col>
                  <Col lg={4} span={24} className="mb-3">
                    <p className="mb-0 opacity-70 text-xs">Contact No.</p>
                    <h6 className="font-semibold text-md">
                      {e.address.phone_no}
                    </h6>
                  </Col>
                  <Col lg={4} span={12} className="mb-3">
                    <p className="mb-0 opacity-70 text-xs"> Payment Type </p>
                    <h6 className="font-semibold text-md">{e.order_type} </h6>
                  </Col>

                  {e.order_type === "cod" ? (
                    <>
                      <Col lg={4} span={12} className="mb-3">
                        <p className="mb-0 opacity-70 text-xs">
                          Amount to collect
                        </p>
                        <h6 className="font-semibold text-md">₹ {e.total} </h6>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  <Col lg={4} span={24}>
                    {e.status === "picked" ? (
                      <>
                        <button
                          className="w-full my-2 py-2 px-5 bg-blue-600 hover:shadow-lg hover:bg-blue-700
            transition text-white rounded-md flex justify-center align-center"
                          onClick={() => {
                            // toggleModal(true);
                            set_out_for_delivery(e.id);
                          }}
                        >
                          Start Delivery
                          <ArrowRightOutlined className="ml-3 text-lg" />
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                    {e.status === "out_for_delivery" ? (
                      <>
                        <button
                          className="w-full my-2 py-2 px-5 bg-green-600 hover:shadow-lg hover:bg-green-700
            transition text-white rounded-md flex justify-center align-center"
                          onClick={() => {
                            setOrderId(e.id);
                            toggleModal(true);
                          }}
                        >
                          Complete Delivery
                          <CheckCircleOutlined className="ml-3 text-lg" />
                        </button>
                        <button
                          className="w-full mt-3 my-2 py-2 px-5 border border-slate-400 text-slate-500 hover:shadow-lg
            transition rounded-md flex justify-center align-center"
                          onClick={() => {
                            setOrderId(e.id);
                            setCancleModal(true);
                          }}
                        >
                          <CloseCircleOutlined className="mr-3 text-lg" />
                          Unable to deliver parcel
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                    {e.status === "delivered" ? (
                      <>
                        <button
                          className="w-full my-2 py-2 px-5 bg-slate-300 hover:shadow-lg
                  pointer-events-none text-slate-400 rounded-md flex justify-center align-center"
                        >
                          Delivered
                          <CheckCircleOutlined className="ml-3 text-lg" />
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                    {e.status === "cancelled" ? (
                      <>
                        <button
                          className="w-full my-2 py-2 px-5 bg-red-100 hover:shadow-lg
                  pointer-events-none text-red-400 rounded-md flex justify-center align-center"
                        >
                          Cancelled
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                  {/* 
                  <Col lg={4} span={12} className="mb-3">
                    <p className="mb-0 opacity-70 text-xs">Status</p>
                    <h6 className="font-semibold text-md">{e.status} </h6>
                  </Col> */}
                </Row>
              </div>
            );
          })}
        </div>
      )}

      {!list.length && !loading ? (
        <Alert
          icon={<ExclamationCircleOutlined />}
          type="warning"
          showIcon
          message="No Data available."
        />
      ) : (
        ""
      )}

      <Modal
        title="Deliver the parcel"
        visible={visible}
        onOk={() => {
          verify_customer_otp();
        }}
        okText={"Submit"}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>
          <span className="mb-2 d-block"> Enter OTP </span>
          <Input
            pattern="[0-9]*"
            maxLength={4}
            inputMode="numeric"
            type="number"
            placeholder="Please Enter OTP"
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
        </div>
      </Modal>
      <Modal
        title="Cancle Delivery"
        visible={cancleModal}
        onOk={() => {
          cancle_delivery();
        }}
        okButtonProps={{ danger: true }}
        okText={"Cancle Delivery"}
        cancelText={"Close"}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setCancleModal(false);
        }}
      >
        <div>
          <Radio.Group
            onChange={(e) => {
              setCancleReason(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Radio value={"Customer not available."}>
                Customer not available.
              </Radio>
              <Radio value={"Payment not ready."}>Payment not ready.</Radio>
              <Radio value={"Unable to locate address."}>
                Unable to locate address.
              </Radio>
              <Radio value={"Unable to contact customer."}>
                Unable to contact customer.
              </Radio>
              <Radio value={"Vehicle issue."}>Vehicle issue.</Radio>
            </Space>
          </Radio.Group>
        </div>
      </Modal>
    </>
  );
}

export default OrderDeliveryList;
