import { RightCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { branches, products, store } from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

function AddProduct() {
  const navigate = useNavigate();
  const [branchList, setBranchList] = useState([]);
  const [uploadImages, setUploadImages] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [loading, setLoading] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [specifications, setSpecifications] = useState([
    {
      title: "",
      description: "",
    },
  ]);

  useEffect(() => {
    setLoading(true);
    async function getCategories() {
      return await store
        .get_categories()
        .then((res) => {
          setLoading(false);
          console.log(res.data);
          setProductCategories(res.data);
        })
        .catch((err) => {
          createAlert("error", err.message);
        });
    }

    getCategories();
  }, []);

  const handleFieldUpdate = (index, e) => {
    let newArray = [...specifications];
    let el = newArray[index];
    el[e.target.name] = e.target.value;

    setSpecifications(newArray);
    console.log(specifications);
    // let oldArray = specifications;
  };

  const handleRemoveItem = (e) => {
    let temp = [...specifications];
    temp.splice(e, 1);
    setSpecifications(temp);
  };

  const handleCategoryChange = (e) => {
    let sub_categories = productCategories.find((x) => x.id === e);
    // console.log(sub_categories.sub_category);
    setSubCategories(sub_categories.sub_category);
  };

  const handleFormSuccess = (values) => {
    setLoading(true);
    const formData = new FormData();

    let images = values.product_image.fileList.map((e) => {
      return e.originFileObj;
    });

    let thumbnail = values.thumbnail.fileList.map((e) => {
      return e.originFileObj;
    });

    formData.append("title", values.title);
    formData.append(
      "store_id",
      JSON.parse(localStorage.getItem("user")).user_vendor[0].id
    );
    formData.append("category_id", values.category_id);
    formData.append("sub_category_id", values.subcategory_id);
    formData.append("mrp", values.mrp);
    formData.append("tax", values.tax);
    formData.append("price", values.price);
    formData.append("description", values.description);
    formData.append("long_description", values.long_description);
    formData.append("delivery_charges", values.delivery_charges);
    formData.append("cod", values.cod === true ? "True" : "False");
    formData.append("in_stock", "True");
    formData.append("active", "True");
    formData.append(
      "specification",
      JSON.stringify({ specification: specifications })
    );

    for (let item of images) {
      formData.append("image", item);
    }

    for (let item of thumbnail) {
      formData.append("thumbnail", item);
    }

    console.log(images);

    products.create(formData).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success === true) {
        createAlert("success", "Successfully added new product.");
        // navigate("/products");
      } else {
        createAlert("error", res.message);
      }
    });
  };

  const handleFormError = (error) => {
    console.log(error);
    createAlert("error", "Please enter all the fields correctly.");
  };

  return (
    <>
      <PageBreadcrumb
        items={[
          { link: "/", title: "Home" },
          { link: "/products", title: "Products" },
          { title: "Add" },
        ]}
      />
      <div className="d-flex justify-space-between align-content-center align-center">
        <Header title="Create New Product" />
      </div>

      <div className="form-wrapper">
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={handleFormSuccess}
          onFinishFailed={handleFormError}
          autoComplete="off"
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                className="hide-astricks"
                label="Select Product Category"
                name="category_id"
                rules={[
                  {
                    required: true,
                    message: "Please select main category.",
                  },
                ]}
              >
                <Select
                  placeholder="Select category"
                  onChange={(e) => {
                    handleCategoryChange(e);
                  }}
                >
                  {productCategories &&
                    productCategories.map((item, index) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="hide-astricks"
                label="Select Product Sub Category"
                name="subcategory_id"
                rules={[
                  {
                    required: true,
                    message: "Please select sub category.",
                  },
                ]}
              >
                <Select placeholder="Select category">
                  {subCategories &&
                    subCategories.map((item, index) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                className="hide-astricks"
                label="Product name"
                name="title"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter product name.",
                  },
                ]}
              >
                <Input placeholder="Enter product name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                className="hide-astricks"
                label="MRP"
                name="mrp"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter MRP.",
                  },
                ]}
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter MRP"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                className="hide-astricks"
                label="Selling price"
                name="price"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter selling price.",
                  },
                ]}
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter price"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="hide-astricks"
                label="Tax"
                name="tax"
                hasFeedback
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter Tax"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                className="hide-astricks"
                label="Delivery charges"
                name="delivery_charges"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter delivery charges.",
                  },
                ]}
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter Delivery charges"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="hide-astricks"
                label="Cash on delivery"
                name="cod"
                hasFeedback
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            className="hide-astricks"
            label="Product Short Description"
            name="description"
            hasFeedback
            rules={[
              { required: true, message: "Please enter short description." },
            ]}
          >
            <Input placeholder="Enter Short Description" />
          </Form.Item>
          <Form.Item
            className="hide-astricks"
            label="Product Long Description"
            name="long_description"
            hasFeedback
            rules={[
              { required: true, message: "Please enter long desription." },
            ]}
          >
            <Input.TextArea placeholder="Enter Long Description" />
          </Form.Item>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                className="hide-astricks"
                label="Product Thumbnail"
                name="thumbnail"
                hasFeedback
                rules={[
                  { required: true, message: "Please upload thumbnail." },
                ]}
              >
                <Upload
                  maxCount={1}
                  accept=".jpg, .png"
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={(e) => {
                    if (e.fileList.length) {
                      setThumbnail(e.fileList[0].originFileObj);
                    }
                  }}
                >
                  <Button
                    style={{ minWidth: "100%" }}
                    icon={<UploadOutlined />}
                  >
                    Upload Thumbnail
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="hide-astricks"
                label="Product images"
                name="product_image"
                hasFeedback
                rules={[{ required: true, message: "Please upload images." }]}
              >
                <Upload
                  accept=".jpg, .png"
                  beforeUpload={() => {
                    return false;
                  }}
                  multiple
                >
                  <Button
                    style={{ minWidth: "100%" }}
                    icon={<UploadOutlined />}
                  >
                    Upload Images
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <h3 className="text-lg font-semibold">Product Specifications</h3>
          <hr />

          <div className="mt-4">
            {specifications.map((item, index) => {
              return (
                <Row
                  className="border border-solid border-slate-200"
                  key={index}
                >
                  <Col span={11} className="border-r border-slate-200 p-2">
                    <div className=" flex items-center h-full">
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        name="title"
                        className="focus:outline-0"
                        placeholder="Enter title"
                        onChange={(e) => {
                          handleFieldUpdate(specifications.indexOf(item), e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={11} className="border-r border-slate-200 p-2">
                    <div className=" flex items-center h-full">
                      <textarea
                        type="text"
                        style={{ width: "100%" }}
                        name="description"
                        className="focus:outline-0"
                        placeholder="Enter Description"
                        onChange={(e) => {
                          handleFieldUpdate(specifications.indexOf(item), e);
                        }}
                      ></textarea>
                    </div>
                  </Col>
                  <Col span={2} className="pl-3">
                    <div className="flex items-center h-full">
                      <PlusCircleOutlined
                        className="text-lg mr-4"
                        onClick={() => {
                          setSpecifications((specifications) => [
                            ...specifications,
                            {
                              title: "",
                              description: "",
                            },
                          ]);
                        }}
                      />
                      {index !== 0 ? (
                        <DeleteOutlined
                          onClick={(item, index) => {
                            handleRemoveItem(index);
                          }}
                          className="text-lg"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              className="mt-4"
              loading={loading}
            >
              Create new product
              <RightCircleOutlined />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default AddProduct;
