import { Badge, Button, Col, Input, Modal, Row, Space, Table } from "antd";
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { api, orders } from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";
import _ from "lodash";
import { RightCircleOutlined } from "@ant-design/icons";

function OrderPickupList() {
  const [productsList, setProductsList] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  // Modal
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");

  //
  const [otp, setOtp] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [status, setStatus] = useState();

  const toggleModal = (e) => {
    setVisible(e);
  };

  const verify_vendor_otp = (id) => {
    api
      .verify_vendor_otp({
        order_id: orderId,
        otp: otp,
      })
      .then(async (res) => {
        if (res.data.success) {
          setStatus("1");
          await pick_order();
        } else {
          createAlert("error", res.data.message);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        createAlert("error", err.message);
      });
  };

  const pick_order = async (params) => {
    await api
      .change_order_status({
        status: "picked",
        order_id: orderId,
        note: "",
      })
      .then((res) => {
        if (res.data.success) {
          createAlert("success", "Order picked up succesfully");
        }
        toggleModal(false);
        setConfirmLoading(false);
        console.log(res);
      });
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const getOrderData = useCallback(async () => {
    setLoading(true);

    await api.get_delivery_by_store(searchParams.get("id")).then((res) => {
      console.log(res.data);
      let pickup_list = res.data.data.filter((e) => e.status === "packed");
      setList(pickup_list);
      console.log("pickup_list", pickup_list);
    });
  }, [searchParams]);

  useEffect(() => {
    setLoading(true);
    getOrderData();
  }, [getOrderData, status]);

  return (
    <>
      <PageBreadcrumb
        items={[
          { title: "Pickup stores", link: "/orders" },
          { title: list[0]?.source_address.store_name },
        ]}
      />
      {/* <div className="d-flex justify-space-between align-content-center align-center my-5">
        <h5 className="font-bold text-xl"> Pick up list </h5>
      </div> */}
      {list.length ? (
        <div className="mt-5">
          <h5 className="font-bold text-xl mb-1">
            {list[0]?.source_address.store_name}
          </h5>
          <p className="text-slate-700 mb-2">
            {list[0].source_address.address}
          </p>
        </div>
      ) : (
        ""
      )}

      {/* list */}
      <div className="list mt-8">
        {list.map((e) => {
          return (
            <div className="p-4 bg-slate-100 rounded-md mb-4" key={e.id}>
              <Row>
                <Col lg={4} span={6}>
                  <p className="mb-0 opacity-70 text-xs"> ID </p>
                  <h6 className="font-semibold text-md"> #{e.id} </h6>
                </Col>
                <Col lg={8} span={12}>
                  <p className="mb-0 opacity-70 text-xs"> Product Name </p>
                  <h6 className="font-semibold text-md"> {e.product} </h6>
                </Col>
                <Col lg={4} span={6}>
                  <p className="mb-0 opacity-70 text-xs"> Quantity </p>
                  <h6 className="font-semibold text-md"> {e.quantity} </h6>
                </Col>
                <Col lg={4} span={24}>
                  <button
                    className="py-2 w-full my-3 px-5 bg-slate-500 hover:shadow-lg hover:bg-slate-700
                    transition text-white rounded-full flex justify-center align-center"
                    onClick={() => {
                      setOrderId(e.id);
                      toggleModal(true);
                    }}
                  >
                    Pickup Order
                    <RightCircleOutlined className="ml-3 text-lg" />
                  </button>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
      <Modal
        title="Pickup the order"
        visible={visible}
        onOk={verify_vendor_otp}
        okText={"Confirm Pickup Order"}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>
          <span className="mb-2 d-block"> Enter OTP </span>
          <Input
            type="number"
            inputMode="number"
            placeholder="Please Enter OTP"
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          ></Input>
        </div>
      </Modal>
    </>
  );
}

export default OrderPickupList;
