import {
  CheckCircleFilled,
  CloseOutlined,
  DropboxCircleFilled,
  ExclamationCircleOutlined,
  HomeFilled,
  TagFilled,
} from "@ant-design/icons";
import { Alert, Col, Modal, Row, Select, Spin, Steps } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { orders } from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";

const { Option } = Select;
const { Step } = Steps;
const { confirm } = Modal;

function OrderDetails() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderDetails, setOrderDetails] = useState();
  const [currentStep, setCurrentStep] = useState(0);

  const showPromiseConfirm = (text, status, id) => {
    confirm({
      title: `Are you sure you want to change order status as ${text} ?`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: `You will not be able to reverse the order status.`,
      onOk() {
        return new Promise((resolve, reject) => {
          changeStatus(status, id).then((res) => {
            if (res.success) {
              resolve();
            } else {
              reject();
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const status_list = [
    {
      id: 0,
      title: "accepted",
      buttonText: "Pack / Process",
      next: "onprocess",
    },
    {
      id: 1,
      title: "onprocess",
      buttonText: "Dispatch / Ship",
      next: "on_the_way",
    },
    {
      id: 2,
      title: "on_the_way",
      buttonText: "Mark Delivered",
      next: "delivered",
    },
    {
      id: 3,
      title: "delivered",
      buttonText: "Delivered",
    },
    {
      id: 3,
      title: "cancelled",
      buttonText: "Cancelled",
    },
  ];

  const changeStatus = (status, order_id) => {
    orders.changeStatus({ status: status, order_id: order_id }).then((res) => {
      console.log(res);
      if (res.success) {
        getOrderData();
      } else {
        createAlert("error", res.message);
      }
    });
  };

  const getOrderData = useCallback(async () => {
    setLoading(true);
    await orders.getSingle(+searchParams.get("id")).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        setOrderDetails(res.data);
        let step = status_list.find((e) => e.title === res.data.status).id;
        console.log("step", step);
        setCurrentStep(step);
      } else {
        createAlert("error", "Order Not Found.");
      }
    });
  }, [searchParams]);

  useEffect(() => {
    setLoading(true);
    getOrderData();
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        {orderDetails && !loading ? (
          <>
            <PageBreadcrumb
              items={[{ link: "/", title: "Home" }, { title: "Orders" }]}
            />
            <div className="d-flex justify-space-between align-content-center align-center">
              <Header title="Order : 1" />
              {/* <div
                className="badge  text-sky-700 bg-slate-100 text-lg font-bold 
            rounded-full px-5 py-2"
              >
                Order Placed
              </div> */}
            </div>

            {orderDetails.status !== "cancelled" ? (
              <Row>
                <Col span={24}>
                  <Steps className="mb-10" current={currentStep}>
                    <Step title="Placed" icon={<CheckCircleFilled />} />
                    <Step title="Packed" icon={<DropboxCircleFilled />} />
                    <Step title="Shipped" icon={<TagFilled />} />
                    <Step title="Delivered" icon={<HomeFilled />} />
                  </Steps>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <hr className="mt-0 mb-10 border-slate-200" />

            <Row gutter={20}>
              <Col span={24}></Col>
              <Col span={12}>
                <h1 className="font-medium text-lg"> Customer Details </h1>

                <div
                  className="details my-5 p-5 bg-white border
           border-slate-200 rounded"
                >
                  <div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Name</p>
                      <p className="text-slate-600">
                        {orderDetails.user.first_name}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Email</p>
                      <p className="text-slate-600">
                        {orderDetails.user.email}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Phone</p>
                      <p className="text-slate-600">
                        {orderDetails.user.username}
                      </p>
                    </div>
                    <hr className="my-5" />
                    <div className="flex flex-col mb-3">
                      <p className="text-slate-400"> Delivery Address</p>
                      <p className="text-slate-600">
                        {orderDetails.address.address_line_1}
                      </p>
                      <p className="text-slate-600">
                        {orderDetails.address.address_line_2}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Pincode </p>
                      <p className="text-slate-600">
                        {orderDetails.address.pincode}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> City </p>
                      <p className="text-slate-600">
                        {orderDetails.address.city}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> State </p>
                      <p className="text-slate-600">
                        {orderDetails.address.state}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <h1 className="font-medium text-lg"> Order Details </h1>
                <div
                  className="details my-5 p-5 bg-white border 
          border-slate-200  rounded"
                >
                  <div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Order Id </p>
                      <p className="text-slate-600"> {orderDetails.id}</p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Order Status </p>
                      <p className="text-slate-600"> {orderDetails.status}</p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Date </p>
                      <p className="text-slate-600">
                        {moment(orderDetails.timestamp).format(
                          "YYYY-MM-DD hh:mm A"
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> No. of Items </p>
                      <p className="text-slate-600">{orderDetails.quantity}</p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Payment Mode </p>
                      <p className="text-slate-600">
                        {orderDetails.order_type}
                      </p>
                    </div>
                    <hr className="my-5" />
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Sub Total </p>
                      <p className="text-slate-600">
                        ₹ {orderDetails.sub_total}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Tax Total </p>
                      <p className="text-slate-600">
                        ₹ {orderDetails.tax_total}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-slate-400"> Total Amount </p>
                      <p className="text-slate-600 font-bold">
                        ₹ {orderDetails.total}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row gutter={40}>
              <Col span={12}>
                <h1 className="text-lg font-medium mt-5 mb-4">
                  Product Details
                </h1>
                <div className="order-product p-5 border border-slate-200 border-solid bg-white">
                  <Row>
                    <Col span={8}>
                      <div
                        className="border-slate-200 border border-solid p-2 rounded-sm"
                        style={{
                          height: "120px",
                          width: "120px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          className="object-contain"
                          src="https://offersuperstar.com/media/product/image/711ymgrynSL._SL1500_.jpg"
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col span={16}>
                      <div className="flex flex-col h-full">
                        <div className="flex justify-between">
                          <h2 className="text-lg">
                            {orderDetails.product.title}
                          </h2>
                        </div>
                        <p className="text-slate-600 font-normal"># 456-A</p>
                        <div className="flex justify-start mt-auto">
                          <div className="text-md mt-3">
                            <p className="text-md">
                              <span className="text-gray-400 font-light">
                                Quantity :&nbsp;
                              </span>
                              {orderDetails.product.quantity}
                            </p>
                            <span className="text-gray-400 font-light">
                              Product price:&nbsp;
                            </span>
                            ₹ {orderDetails.product.price}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <h1 className="text-lg font-medium mt-5 mb-4">
                  Order Tracking
                </h1>
                <div
                  className="order-product p-5 border border-slate-200 border-solid 
      bg-white"
                >
                  <div className="flex justify-between items-center mb-3 border-b border-slate-200 pb-3">
                    <p> Accepted </p>
                    <p className="tracking-tight">
                      {" "}
                      {moment(orderDetails.timestamp).format(
                        "YYYY-MM-DD -- hh:mm A"
                      )}
                    </p>
                  </div>

                  {orderDetails.order_status.map((e) => {
                    return (
                      <div className="flex justify-between items-center mb-3 border-b border-slate-200 pb-3">
                        <p className="capitalize"> {e.status} </p>
                        <p className="tracking-tight">
                          {moment(e.timestamp).format("YYYY-MM-DD -- hh:mm A")}
                        </p>
                      </div>
                    );
                  })}
                </div>

                <button
                  className="
              mt-4
             flex items-center justify-center mr-2
             bg-sky-500 shadow-sm shadow-sky-200 px-4 py-2 rounded-sm text-white font-medium 
             transition hover:bg-sky-600 hover:shadow-sky-100 hover:shadow-lg focus:ring-0 
             disabled:cursor-not-allowed
             disabled:bg-sky-900 
             disabled:text-slate-300"
                  disabled={
                    orderDetails.status === "delivered" ||
                    orderDetails.status === "cancelled"
                  }
                  onClick={() => {
                    showPromiseConfirm(
                      status_list.find((e) => e.title === orderDetails.status)
                        .buttonText,
                      status_list.find((e) => e.title === orderDetails.status)
                        .next,
                      orderDetails.id
                    );
                  }}
                >
                  {
                    status_list.find((e) => e.title === orderDetails.status)
                      .buttonText
                  }
                  &nbsp;Order
                </button>
              </Col>
              {orderDetails.status === "cancelled" ||
              orderDetails.status === "delivered" ? (
                ""
              ) : (
                <Col span={12} className="mt-8">
                  <button
                    className="
           flex items-center justify-center mr-2
           border border-solid border-red-500 text-red-500
             px-4 py-2 rounded-sm font-medium 
           transition hover:bg-red-500 hover:text-white hover:shadow-red-100 hover:shadow-lg focus:ring-0"
                    onClick={() => {
                      showPromiseConfirm(
                        "Cancelled",
                        "cancelled",
                        orderDetails.id
                      );
                    }}
                  >
                    <CloseOutlined className="mr-2" /> Cancle Order
                  </button>
                </Col>
              )}
            </Row>
          </>
        ) : loading === false ? (
          <Alert type="warning" message="Order not found." />
        ) : (
          ""
        )}
      </Spin>
    </>
  );
}

export default OrderDetails;
