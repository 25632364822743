import { notification } from "antd";

export const createAlert = (type, message) => {
  if (type === "error") {
    notification.error({
      message: "Error",
      description: `${message}`,
      placement: "top",
    });
  }
  if (type === "success") {
    notification.success({
      message: "Success",
      description: `${message}`,
      placement: "top",
    });
  }
};
